import { getString } from '@/language'


export const getCompositionParts = (item, type) => {
  return item &&
    item.productData &&
    item.productData.construction &&
    Array.isArray(item.productData.construction.composition)
    ? item.productData.construction.composition.filter(
      (part) => part.type === type
    )
    : []
}

const getCompositionPartValues = (item, type) => {
  const values = []

  const parts = getCompositionParts(item, type)
  parts.map((part) => values.push(part.value))

  return values
}

//  TODO: 
//✅ plainsGrains aufbauen wie allInsulations -> heißt key, mit den ganzen werten die dazu gehlören. 
//🛑 anschließend: 1 funktion die mir die werte so ausgibt, wie ich sie für die Koeffizenten brauche
// ✅ und 1 funktion, damit ich das im meiner plasterGrain vue componente auslesen kann (buttons) orientieren an insulationThickness()
// ✅ after all allInsulations -> m2PerBund sicher stellen dass das alles zahlen sind. keine strings

// Dübelvergrößerung die Texte aus der Excel anzeigen.

const plasterGrains = {

  'finishingPlaster-1': [
    {
      title: { de: 'Oberputzkörnung' },
      output: 'K1,5',
      type: "plasterGrain",
      value: 'k15',
      coefficient: 2.3,
      unitOfMeasurement: 'kg'
    },
    {
      title: { de: 'Oberputzkörnung' },
      output: 'K2,0',
      type: "plasterGrain",
      value: 'k20',
      coefficient: 3,
      unitOfMeasurement: 'kg'
    },
    {
      title: { de: 'Oberputzkörnung' },
      output: 'K3,0',
      type: "plasterGrain",
      value: 'k30',
      coefficient: 4,
      unitOfMeasurement: 'kg'
    },
    {
      title: { de: 'Oberputzkörnung' },
      output: 'R1,5',
      type: "plasterGrain",
      value: 'r15',
      coefficient: 2.5,
      unitOfMeasurement: 'kg'
    },
    {
      title: { de: 'Oberputzkörnung' },
      output: 'R2,0',
      type: "plasterGrain",
      value: 'r20',
      coefficient: 3,
      unitOfMeasurement: 'kg'
    },
    {
      title: { de: 'Oberputzkörnung' },
      output: 'R3,0',
      type: "plasterGrain",
      value: 'r30',
      coefficient: 4,
      unitOfMeasurement: 'kg'
    },
    {
      title: { de: 'Oberputzkörnung' },
      output: 'MP',
      type: "plasterGrain",
      value: 'mp',
      coefficient: 1,
      unitOfMeasurement: 'kg'
    }
  ],
  'finishingPlaster-2': [
    {
      title: { de: 'Oberputzkörnung' },
      output: 'K1,5',
      type: "plasterGrain",
      value: 'k15',
      coefficient: 2.3,
      unitOfMeasurement: 'kg'
    },
    {
      title: { de: 'Oberputzkörnung' },
      output: 'K2,0',
      type: "plasterGrain",
      value: 'k20',
      coefficient: 3,
      unitOfMeasurement: 'kg'
    },
    {
      title: { de: 'Oberputzkörnung' },
      output: 'K3,0',
      type: "plasterGrain",
      value: 'k30',
      coefficient: 4,
      unitOfMeasurement: 'kg'
    },
    {
      title: { de: 'Oberputzkörnung' },
      output: 'R1,5',
      type: "plasterGrain",
      value: 'r15',
      coefficient: 2.5,
      unitOfMeasurement: 'kg'
    },
    {
      title: { de: 'Oberputzkörnung' },
      output: 'R2,0',
      type: "plasterGrain",
      value: 'r20',
      coefficient: 3,
      unitOfMeasurement: 'kg'
    },
    {
      title: { de: 'Oberputzkörnung' },
      output: 'R3,0',
      type: "plasterGrain",
      value: 'r30',
      coefficient: 4,
      unitOfMeasurement: 'kg'
    },
    {
      title: { de: 'Oberputzkörnung' },
      output: 'MP',
      type: "plasterGrain",
      value: 'mp',
      coefficient: 1,
      unitOfMeasurement: 'kg'
    }
  ],
  'finishingPlaster-3': []
}


// @sascha -> das ist die Funktion mit die die indexe mit gibt.
export const plasterGrainsCoefficients = function () {
  // const grains = Array.from(plasterGrains['finishingPlaster-1']).flatMap(grain => {
  //   console.log(grain)
  //  return {[grain.value] : [grain.coefficient, grain.unitOfMeasurement]  }
  // });


  const grainsList = []
  for (const grain of plasterGrains['finishingPlaster-1']) {
    grainsList.push({ [grain.value]: [grain.coefficient, grain.unitOfMeasurement] })
  }
  return grainsList
}

const allCoefficients = {
  'insulationGlue-1': [5, 'kg'],
  'insulationGlue-2': [4, 'kg'],
  'insulationGlue-3': [4, 'kg'],
  'reinforcingCompound-1': [5, 'kg'],
  'reinforcingCompound-2': [6, 'kg'],
  'reinforrcingCompound-3': [9.25, 'kg'],
  'reinforcingCompound-4': [6.5, 'kg'],
  'reinforcingFabric-1': [1, 'm'],
  'renderPrimer-1': [0.3, 'kg'],
  // ...plasterGrainsCoefficients()
  'k15': [2.3, 'kg'],
  'k20': [3, 'kg'],
  'k30': [4, 'kg'],
  'r15': [2.5, 'kg'],
  'r20': [3, 'kg'],
  'r30': [4, 'kg'],
  'mp': [1, 'kg'],
  'facadePaint-1': [0.37, 'l'],
  'facadePaint-2': [0.37, 'l'],
  'reinforcingCompound-3': [6.5, 'kg'],
}

const allInsulations = {
  'insulation-1': {
    title: 'EPS 032',
    value: 'insulation-1',
    specifics: [
      {
        title: 'SW WDVS EPS 032 2 CM 12 M2 BND',
        width: 20,
        m2PerBund: 12,
        explanation: 'Dübel technisch nicht möglich',
      },
      {
        title: 'SW WDVS EPS 032 3 CM 8 M2 BND',
        width: 30,
        m2PerBund: 8,
        explanation: 'Dübel technisch nicht möglich',
      },
      {
        title: 'SW WDVS EPS 032 4 CM 6 M2 BND',
        width: 40,
        m2PerBund: 6,
        explanation: 'Dübel technisch nicht möglich',
      },
      {
        title: 'SW WDVS EPS 032 5 CM 4,5 M2 BND',
        width: 50,
        m2PerBund: 4.5,
        explanation: 'Dübel technisch nicht möglich',
      },
      {
        title: 'SW WDVS EPS 032 6 CM 4 M2 BND',
        width: 60,
        m2PerBund: 4,
        explanation: 100,
      },
      {
        title: 'SW WDVS EPS 032 8 CM 3 M2 BND',
        width: 80,
        m2PerBund: 3,
        explanation: 120,
      },
      {
        title: 'SW WDVS EPS 032 10 CM 2 M2 BND',
        width: 100,
        m2PerBund: 2,
        explanation: 140,
      },
      {
        title: 'SW WDVS EPS 032 12 CM 2 M2 BND',
        width: 120,
        m2PerBund: 2,
        explanation: 160,
      },
      {
        title: 'SW WDVS EPS 032 14 CM 1,5 M2 BND',
        width: 140,
        m2PerBund: 1.5,
        explanation: 180,
      },
      {
        title: 'SW WDVS EPS 032 16 CM 1,5 M2 BND',
        width: 160,
        m2PerBund: 1.5,
        explanation: 200,
      },
      {
        title: 'SW WDVS EPS 032 18 CM 1 M2 BND',
        width: 180,
        m2PerBund: 1,
        explanation: 220,
      },
      {
        title: 'SW WDVS EPS 032 20 CM 1 M2 BND',
        width: 200,
        m2PerBund: 1,
        explanation: 240,
      },
      {
        title: 'SW WDVS EPS 032 22 CM 1 M2 BND',
        width: 220,
        m2PerBund: 1,
        explanation: 260,
      },
      {
        title: 'SW WDVS EPS 032 24 CM 1 M2 BND',
        width: 240,
        m2PerBund: 1,
        explanation: 280,
      },
      {
        title: 'SW WDVS EPS 032 26 CM 1 M2 BND',
        width: 260,
        m2PerBund: 1,
        explanation: 300,
      },
      {
        title: 'SW WDVS EPS 032 28 CM 1 M2 BND',
        width: 280,
        m2PerBund: 1,
        explanation: 'Kein Dübel möglich',
      },
      {
        title: 'SW WDVS EPS 032 30 CM 1 M2 BND',
        width: 300,
        m2PerBund: 1,
        explanation: 'Kein Dübel möglich',
      },
      {
        title: 'SW WDVS EPS 032 32 CM 1 M2 BND',
        width: 320,
        m2PerBund: 1,
        explanation: 'Kein Dübel möglich',
      },
      {
        title: 'SW WDVS EPS 032 34 CM 1 M2 BND',
        width: 340,
        m2PerBund: 1,
        explanation: 'Kein Dübel möglich',
      },
      {
        title: 'SW WDVS EPS 032 36 CM 1 M2 BND',
        width: 360,
        m2PerBund: 1,
        explanation: 'Kein Dübel möglich',
      },
      {
        title: 'SW WDVS EPS 032 38 CM 1 M2 BND',
        width: 380,
        m2PerBund: 1,
        explanation: 'Kein Dübel möglich',
      },
      {
        title: 'SW WDVS EPS 032 40 CM 1 M2 BND',
        width: 400,
        m2PerBund: 1,
        explanation: 'Kein Dübel möglich',
      },
    ],
  },
  'insulation-2': {
    title: 'EPS 032 Nut und Feder',
    value: 'insulation-2',
    specifics: [
      {
        title: 'SW WDVS EPS 032 NFBS 4 CM 6 M2 BND',
        width: 40,
        m2PerBund: 6,
        explanation: 'Dübel technisch nicht möglich',
      },
      {
        title: 'SW WDVS EPS 032 NFBS 5 CM 4,5 M2 BND',
        width: 50,
        m2PerBund: 4.5,
        explanation: 'Dübel technisch nicht möglich',
      },
      {
        title: 'SW WDVS EPS 032 NFBS 6 CM 4 M2 BND',
        width: 60,
        m2PerBund: 4,
        explanation: 100,
      },
      {
        title: 'SW WDVS EPS 032 NFBS 8 CM 3 M2 BND',
        width: 80,
        m2PerBund: 3,
        explanation: 120,
      },
      {
        title: 'SW WDVS EPS 032 NFBS 10 CM 2 M2 BND',
        width: 100,
        m2PerBund: 2,
        explanation: 140,
      },
      {
        title: 'SW WDVS EPS 032 NFBS 12 CM 2 M2 BND',
        width: 120,
        m2PerBund: 2,
        explanation: 160,
      },
      {
        title: 'SW WDVS EPS 032 NFBS 14 CM 1,5 M2 BND',
        width: 140,
        m2PerBund: 1.5,
        explanation: 180,
      },
      {
        title: 'SW WDVS EPS 032 NFBS 16 CM 1,5 M2 BND',
        width: 160,
        m2PerBund: 1.5,
        explanation: 200,
      },
      {
        title: 'SW WDVS EPS 032 NFBS 18 CM 1 M2 BND',
        width: 180,
        m2PerBund: 1,
        explanation: 220,
      },
      {
        title: 'SW WDVS EPS 032 NFBS 20 CM 1 M2 BND',
        width: 200,
        m2PerBund: 1,
        explanation: 240,
      },
      {
        title: 'SW WDVS EPS 032 NFBS 22 CM 1 M2 BND',
        width: 220,
        m2PerBund: 1,
        explanation: 260,
      },
      {
        title: 'SW WDVS EPS 032 NFBS 24 CM 1 M2 BND',
        width: 240,
        m2PerBund: 1,
        explanation: 280,
      },
      {
        title: 'SW WDVS EPS 032 NFBS 26 CM 1 M2 BND',
        width: 260,
        m2PerBund: 1,
        explanation: 300,
      },
      {
        title: 'SW WDVS EPS 032 NFBS 28 CM 1 M2 BND',
        width: 280,
        m2PerBund: 1,
        explanation: 'Kein Dübel möglich',
      },
      {
        title: 'SW WDVS EPS 032 NFBS 30 CM 1 M2 BND',
        width: 300,
        m2PerBund: 1,
        explanation: 'Kein Dübel möglich',
      },
    ],
  },
  'insulation-3': {
    title: 'EPS 034',
    value: 'insulation-3',
    specifics: [
      {
        title: 'SW WDVS EPS 034 4 CM 6 M2 BND',
        width: 40,
        m2PerBund: 6,
        explanation: 'Kein Dübel möglich',
      },
      {
        title: 'SW WDVS EPS 034 6 CM 4 M2 BND',
        width: 60,
        m2PerBund: 4,
        explanation: 100,
      },
      {
        title: 'SW WDVS EPS 034 8 CM 3 M2 BND',
        width: 80,
        m2PerBund: 3,
        explanation: 120,
      },
      {
        title: 'SW WDVS EPS 034 10 CM 2 M2 BND',
        width: 100,
        m2PerBund: 2,
        explanation: 140,
      },
      {
        title: 'SW WDVS EPS 034 12 CM 2 M2 BND',
        width: 120,
        m2PerBund: 2,
        explanation: 160,
      },
      {
        title: 'SW WDVS EPS 034 14 CM 1,5 M2 BND',
        width: 140,
        m2PerBund: 1.5,
        explanation: 180,
      },
      {
        title: 'SW WDVS EPS 034 16 CM 1,5 M2 BND',
        width: 160,
        m2PerBund: 1.5,
        explanation: 200,
      },
      {
        title: 'SW WDVS EPS 034 18 CM 1 M2 BND',
        width: 180,
        m2PerBund: 1,
        explanation: 220,
      },
      {
        title: 'SW WDVS EPS 034 20 CM 1 M2 BND',
        width: 200,
        m2PerBund: 1,
        explanation: 240,
      },
      {
        title: 'SW WDVS EPS 034 22 CM 1 M2 BND',
        width: 220,
        m2PerBund: 1,
        explanation: 260,
      },
      {
        title: 'SW WDVS EPS 034 24 CM 1 M2 BND',
        width: 240,
        m2PerBund: 1,
        explanation: 280,
      },
      {
        title: 'SW WDVS EPS 034 26 CM 1 M2 BND',
        width: 260,
        m2PerBund: 1,
        explanation: 300,
      },
      {
        title: 'SW WDVS EPS 034 28 CM 1 M2 BND',
        width: 280,
        m2PerBund: 1,
        explanation: 'Kein Dübel möglich',
      },
      {
        title: 'SW WDVS EPS 034 30 CM 1 M2 BND',
        width: 300,
        m2PerBund: 1,
        explanation: 'Kein Dübel möglich',
      },
    ],
  },
  'insulation-4': {
    title: 'EPS 034 Nut und Feder',
    value: 'insulation-4',
    specifics: [
      {
        title: 'SW WDVS EPS 034 NFBS 4 CM 6 M2 BND',
        width: 40,
        m2PerBund: 6,
        explanation: 'Kein Dübel möglich',
      },
      {
        title: 'SW WDVS EPS 034 NFBS 6 CM 4 M2 BND',
        width: 60,
        m2PerBund: 4,
        explanation: 100,
      },
      {
        title: 'SW WDVS EPS 034 NFBS 8 CM 3 M2 BND',
        width: 80,
        m2PerBund: 3,
        explanation: 120,
      },
      {
        title: 'SW WDVS EPS 034 NFBS 10 CM 2 M2 BND',
        width: 100,
        m2PerBund: 2,
        explanation: 140,
      },
      {
        title: 'SW WDVS EPS 034 NFBS 12 CM 2 M2 BND',
        width: 120,
        m2PerBund: 2,
        explanation: 160,
      },
      {
        title: 'SW WDVS EPS 034 NFBS 14 CM 1,5 M2 BND',
        width: 140,
        m2PerBund: 1.5,
        explanation: 180,
      },
      {
        title: 'SW WDVS EPS 034 NFBS 16 CM 1,5 M2 BND',
        width: 160,
        m2PerBund: 1.5,
        explanation: 200,
      },
      {
        title: 'SW WDVS EPS 034 NFBS 18 CM 1 M2 BND',
        width: 180,
        m2PerBund: 1,
        explanation: 220,
      },
      {
        title: 'SW WDVS EPS 034 NFBS 20 CM 1 M2 BND',
        width: 200,
        m2PerBund: 1,
        explanation: 240,
      },
      {
        title: 'SW WDVS EPS 034 NFBS 22 CM 1 M2 BND',
        width: 220,
        m2PerBund: 1,
        explanation: 260,
      },
      {
        title: 'SW WDVS EPS 034 NFBS 24 CM 1 M2 BND',
        width: 240,
        m2PerBund: 1,
        explanation: 280,
      },
      {
        title: 'SW WDVS EPS 034 NFBS 26 CM 1 M2 BND',
        width: 260,
        m2PerBund: 1,
        explanation: 300,
      },
      {
        title: 'SW WDVS EPS 034 NFBS 28 CM 1 M2 BND',
        width: 280,
        m2PerBund: 1,
        explanation: 'Kein Dübel möglich',
      },
      {
        title: 'SW WDVS EPS 034 NFBS 30 CM 1 M2 BND',
        width: 300,
        m2PerBund: 1,
        explanation: 'Kein Dübel möglich',
      },
    ],
  },
  'insulation-5': {
    title: 'MP 035',
    value: 'insulation-5',
    specifics: [
      {
        title: 'SW WDVS MP 035 6 CM 2 M2 BND',
        width: 60,
        m2PerBund: 2,
        explanation: 100,
      },
      {
        title: 'SW WDVS MP 035 8 CM 1,5 M2 BND',
        width: 80,
        m2PerBund: 1.5,
        explanation: 120,
      },
      {
        title: 'SW WDVS MP 035 10 CM 1,5 M2 BND',
        width: 100,
        m2PerBund: 1.5,
        explanation: 140,
      },
      {
        title: 'SW WDVS MP 035 12 CM 1,5 M2 BND',
        width: 120,
        m2PerBund: 1.5,
        explanation: 160,
      },
      {
        title: 'SW WDVS MP 035 14 CM 1 M2 BND',
        width: 140,
        m2PerBund: 1,
        explanation: 180,
      },
      {
        title: 'SW WDVS MP 035 16 CM 1 M2 BND',
        width: 160,
        m2PerBund: 1,
        explanation: 200,
      },
      {
        title: 'SW WDVS MP 035 18 CM 1 M2 BND',
        width: 180,
        m2PerBund: 1,
        explanation: 220,
      },
      {
        title: 'SW WDVS MP 035 20 CM 1 M2 BND',
        width: 200,
        m2PerBund: 1,
        explanation: 240,
      },
      {
        title: 'SW WDVS MP 035 22 CM 0,5 M2 BND',
        width: 220,
        m2PerBund: 0.5,
        explanation: 260,
      },
      {
        title: 'SW WDVS MP 035 24 CM 0,5 M2 BND',
        width: 240,
        m2PerBund: 0.5,
        explanation: 280,
      },
      {
        title: 'SW WDVS MP 035 26 CM 0,5 M2 BND',
        width: 260,
        m2PerBund: 0.5,
        explanation: 300,
      },
      {
        title: 'SW WDVS MP 035 28 CM 0,5 M2 BND',
        width: 280,
        m2PerBund: 0.5,
        explanation: 'Kein Dübel möglich',
      },
      {
        title: 'SW WDVS MP 035 30 CM 0,5 M2 BND',
        width: 300,
        m2PerBund: 0.5,
        explanation: 'Kein Dübel möglich',
      },
    ],
  },
  'insulation-6': {
    title: 'MP 035 X2',
    value: 'insulation-6',
    specifics: [
      {
        title: 'SW WDVS ML 041 4 CM 2,88 M2 BND',
        width: 40,
        m2PerBund: 2.88,
        explanation: 'Dübel technisch nicht möglich',
      },
      {
        title: 'SW WDVS ML 041 5 CM 1,92 M2 BND',
        width: 50,
        m2PerBund: 1.92,
        explanation: 'Dübel technisch nicht möglich',
      },
      {
        title: 'SW WDVS ML 041 6 CM 1,92 M2 BND',
        width: 60,
        m2PerBund: 1.92,
        explanation: 100,
      },
      {
        title: 'SW WDVS ML 041 8 CM 1,44 M2 BND',
        width: 80,
        m2PerBund: 1.44,
        explanation: 120,
      },
      {
        title: 'SW WDVS ML 041 10 CM 0,96 M2 BND',
        width: 100,
        m2PerBund: 0.96,
        explanation: 140,
      },
      {
        title: 'SW WDVS ML 041 12 CM 0,96 M2 BND',
        width: 120,
        m2PerBund: 0.96,
        explanation: 160,
      },
      {
        title: 'SW WDVS ML 041 14 CM 0,96 M2 BND',
        width: 140,
        m2PerBund: 0.96,
        explanation: 180,
      },
      {
        title: 'SW WDVS ML 041 16 CM 0,96 M2 BND',
        width: 160,
        m2PerBund: 0.96,
        explanation: 200,
      },
      {
        title: 'SW WDVS ML 041 18 CM 0,96 M2 BND',
        width: 180,
        m2PerBund: 0.96,
        explanation: 220,
      },
      {
        title: 'SW WDVS ML 041 20 CM 0,96 M2 BND',
        width: 200,
        m2PerBund: 0.96,
        explanation: 240,
      },
      {
        title: 'SW WDVS ML 041 22 CM 0,48 M2 BND',
        width: 220,
        m2PerBund: 0.48,
        explanation: 260,
      },
      {
        title: 'SW WDVS ML 041 24 CM 0,48 M2 BND',
        width: 240,
        m2PerBund: 0.48,
        explanation: 280,
      },
    ],
  },
}

const availables = {
  goal: ['goal-1', 'goal-2', 'goal-3', null],
  system: ['system-1', 'system-2', 'system-3', null],
  insulationGlue: [
    'insulationGlue-1',
    'insulationGlue-2',
    'insulationGlue-3',
    null,
  ],
  insulation: [
    'insulation-1',
    'insulation-2',
    'insulation-3',
    'insulation-4',
    'insulation-5',
    'insulation-6',
    null,
  ],
  dowel: ['dowel-1', 'dowel-2', 'dowel-3', null],
  dowelEnlargement: [
    'dowelEnlargement-1',
    'dowelEnlargement-2',
    'dowelEnlargement-3',
    null,
  ],
  reinforcingCompound: [
    'reinforcingCompound-1',
    'reinforcingCompound-2',
    'reinforcingCompound-3',
    'reinforcingCompound-4',
    null,
  ],
  reinforcingFabric: ['reinforcingFabric-1', null],
  renderPrimer: ['renderPrimer-1', null],
  finishingPlaster: [
    'finishingPlaster-1',
    'finishingPlaster-2',
    'finishingPlaster-3',
    null,
  ],
  facadePaint: ['facadePaint-1', 'facadePaint-2', null],
  insulationWidth: [
    20, 30, 40, 50, 60, 80, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280,
    300, 320, 340, 360, 380, 400,
  ],
  plasterGrain: [
    'k15', 'k20', 'k30', 'r15', 'r20', 'r30', 'mp'
  ],
}

export default availables

export const objMap = {
  goal: (item) => {
    const values = getCompositionPartValues(item, 'goal')
    return values.length > 0 ? values : [null]
  },

  system: (item) => {
    const values = getCompositionPartValues(item, 'system')
    return values.length > 0 ? values : [null]
  },

  insulationGlue: (item) => {
    const values = getCompositionPartValues(item, 'insulationGlue')
    return values.length > 0 ? values : [null]
  },

  insulation: (item) => {
    const values = getCompositionPartValues(item, 'insulation')
    return values.length > 0 ? values : [null]
  },

  dowel: (item) => {
    const values = getCompositionPartValues(item, 'dowel')
    return values.length > 0 ? values : [null]
  },

  dowelEnlargement: (item) => {
    const values = getCompositionPartValues(item, 'dowelEnlargement')
    return values.length > 0 ? values : [null]
  },
  reinforcingCompound: (item) => {
    const values = getCompositionPartValues(item, 'reinforcingCompound')
    return values.length > 0 ? values : [null]
  },

  reinforcingFabric: (
    item) => {
    const values = getCompositionPartValues(item, 'reinforcingFabric')
    return values.length > 0 ? values : [null]
  },

  renderPrimer: (item) => {
    const values = getCompositionPartValues(item, 'renderPrimer')
    return values.length > 0 ? values : [null]
  },
  finishingPlaster: (item) => {
    const values = getCompositionPartValues(item, 'finishingPlaster')
    return values.length > 0 ? values : [null]
  },
  facadePaint: (item) => {
    const values = getCompositionPartValues(item, 'facadePaint')
    return values.length > 0 ? values : [null]
  },
  insulationWidth: (item) => {
    const values = getCompositionPartValues(item, 'insulationWidth')
    return values.length > 0 ? values : [null]
  },
  plasterGrain: (item) => {
    const values = getCompositionPartValues(item, 'plasterGrain')
    return values.length > 0 ? values : [null]
  },
}

export const validate = (name, value) => {
  let result = []

  const values = availables[name] ? availables[name] : []
  if (Array.isArray(value)) {
    for (let i = 0; i < value.length; i++) {
      if (values.indexOf(value[i]) >= 0) result.push(value[i])
    }
  } else if (values.indexOf(value) >= 0) result = [value]

  return result
}

export const insulationThickness = function (insulation = null) {
  const title = getString('insulationThickness')
  if (insulation == null) {
    return Object.values(allInsulations)
  } else {
    return allInsulations[insulation].specifics.map((item) => {
      const itemForDisplay = {
        title: { 'de': title },
        technicalTitle: item.title,
        type: 'insulationWidth', // todo, it should be thickness
        value: item.width,
        width: item.width,
        output: item.width
      }
      return itemForDisplay
    })
  }
}

export const getPlasterGrainsForButtons = function (finishingPlaster = null) {
  if (finishingPlaster != null) {
    return plasterGrains[finishingPlaster]
  }
  return null
}





export const dowelLength = function (insulation = null, insulationThickness = null) {
  if ((insulation != null) && insulationThickness != null) {
    const dowelLengthItem = allInsulations[insulation].specifics.find(item => item.width === insulationThickness)
    const title = getString('dowelLength')
    const itemForDisplay = {
      title: { 'de': title },
      type: 'dowelLength', // todo, it should be thickness
      value: dowelLengthItem.explanation,
      output: dowelLengthItem.explanation
    }
    return itemForDisplay


  } else {
    return null
  }
}

export const coefficients = function () {
  return allCoefficients
}